<template>
  <div class="main-container la-storia" v-if="this.pageData">

    <header class="">
      <div class="image-container">
        <img src="../assets/home-santo.png" alt="">
      </div>
      <!--<img class="image-desktop" src="../assets/la-storia-bg-intro.jpg" alt="">-->
      <div class="container" v-if="this.bannerData && this.bannerData.attributes">
        <h1 v-html="this.bannerData.attributes.field_ui_text_titolo.value">
        </h1>
        <p v-html="this.bannerData.attributes.field_text_with_summary.value">
        </p>
      </div>
    </header>

    <SpallaVideo v-if="this.showSpalla" :spalladata="this.spallaComputedData" @close-spalla="toggleSpalla('close')" />

    <div class="main-content">
      <div class="container">
        <div :class="['video-container', 'storia', { videoPlaying: isPlaying }]" @click="playVideo()">

          <div class="controls">
            <img class="play" src="../assets/play-button.svg" width="600" height="400" alt="">
            <img class="pause" src="../assets/pause-button.svg" width="600" height="400" alt="">
          </div>
          <video ref="video" preload="none" @ended="onEnd()" poster="../assets/video/poster-placeholder-video.jpg" 
            :src="videoUrl" type="video/webm">
            <!--Your browser does not support the video tag.-->
          </video>
        </div>

        <button class="btn icon-right outline testo-video" @click="toggleSpalla">
          <span>{{ this.$store.state.labelData[this.lang].video_button }}</span>
        </button>

      </div>

      <div class="container intro-description" v-if="this.testiPagina[0]">
        <h2 v-html="this.testiPagina[0].attributes.field_ui_text_titolo.value"></h2>
        <p v-html="this.testiPagina[0].attributes.field_text_with_summary.value">
        </p>
      </div>

      <div class="reperti" v-if="this.museumData">

        <div class="container">
          <h2 class="section-title" v-html="this.$store.state.labelData[this.lang].storia.antiquarium">
          </h2>
        </div>

        <div class="container timeline">
          <div class="list">
            <item-tile v-for="(r, index) in this.museumData" :key="'reperto'+index" :routesdata="routesData"
              :itemdata="r"></item-tile>
          </div>
        </div>

      </div>

    </div>
    
  </div>
</template>

<script>
  import SpallaVideo from '@/components/SpallaVideo'
  import ItemTile from '@/components/ItemTile'
  import {
    fetchRoutes,
    fetchNodes,
    fetchSinglePage,
    getTranslation
  } from '../libs/drupalClient'

  export default {
    name: 'la-storia',
    components: {
      SpallaVideo,
      ItemTile
    },
    data: () => {
      return {
        isPlaying: false,
        showSpalla: false,
        pageData: null,
        currentPageID: "c111f013-0c30-4372-b82a-df5445f1f435",
        routesData: [],
        spallaData: {
          spallaTitle: "",
          spallaContent: {},
          spallaImages: "",
          spallaTexts: "",
          spallaCover: "",
          spallaTipo: ""
        },
      }
    },
    metaInfo() {
      return {
        title: this.pagemetatitle,
        meta: [
          {
            property: 'og:title',
            content: this.pagemetatitle
          }
        ]
      }
    },
    computed: {
      spallaComputedData() {
        return this.spallaData
      },
      pagemetatitle() {
        if(this.lang == 'it') return 'La Storia'
        else if(this.lang=='de') return 'Geschichte'
        else return 'History'
      },
      videoUrl() {
        if(this.pageData.field_pagina_base_video)
          return process.env.VUE_APP_ENDPOINT + this.pageData.field_pagina_base_video.attributes.uri.url
        else return ''
      },
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      museumData() {
        return this.$store.state.museumData
      },
      selectedRepertoID() {
        return this.$store.state.selectedRepertoID
      },
      selectedReperto() {
        return this.museumData.find(m => m.id === this.selectedRepertoID)
      },
      bannerData() {
        let a = this.pageData.field_ref_ui_text.filter((f)=>{
          if ( f!=undefined && f.attributes.field_tipo == 'Banner' ){
            //console.log('f',f);
            return f
          }
        })
        return a[0]

      },
      testiPagina() {
        let a = this.pageData.field_ref_ui_text.filter((f)=>{
          if ( f!=undefined && f.attributes.field_tipo == 'Testo pagina' ){
            //console.log('f',f);
            return f
          }
        })
        return a
      }
    },
    methods: {
      //VIDEO 
      play() {
        this.$refs.video.play()
      },
      pause() {
        this.$refs.video.pause()
      },
      onEnd() {
        this.isPlaying = false
      },
      playVideo() {
        if (!this.isPlaying) {
          this.play()
          this.isPlaying = true
        } else {
          this.isPlaying = false
          this.pause()
        }
      },
      toggleSpalla() {
        this.spallaData.spallaTitle = this.pageData.attributes.title
        this.spallaData.spallaContent = this.pageData
        this.spallaData.spallaCover = ""
        this.spallaData.spallaTexts = "field_ref_ui_text"
        this.spallaData.spallaImages = "field_page_immagini"
        this.spallaData.spallaTipo = "Testo video"
        this.showSpalla = !this.showSpalla
      },
      changeVideo() {
        this.pause()
        this.isPlaying = false
        this.$refs.video.src = this.videoUrl
      },
      async findPageData() {
        await fetchSinglePage(this.currentPageID, this.lang)
        .then(res => {
          this.pageData = res
          //console.log('res',res);
        })

        await fetchNodes('reperto', {
          include: ['field_reperto_foto', 'field_reperto_video', 'field_ref_approfondimenti']
        }, this.lang).then(res => {
          this.$store.commit('SET_MUSEUMDATA',
            res.sort((a, b) => {
              //console.log(a.attributes.field_ordinamento)
              return a.attributes.field_ordinamento < b.attributes.field_ordinamento ? -1 : 1
            })
          )
        })
      }
    },
    async mounted() {
      await fetchRoutes().then(r => this.routesData = r.routes)
      await this.findPageData()
    },
    watch: {
      lang() {
        this.showSpalla = false 
        
        getTranslation(this.$route.name, this.lang)
          .then(res => this.$router.push({ path: res }) )

        this.findPageData()
        this.changeVideo()
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.la-storia .main-content:before { display: none; }

.main-container.la-storia {
  header {
    padding-top: 37vh;
    position: relative;

    &:after {
      content: "";
      background: #181923 url("../assets/san-canzian-filigrana-reperto.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 0;
      width: 100%;
      transform: skewY(-5deg);
      height: 100vh;
      padding: 10rem 0rem 0rem 0rem;
      top: -3rem;
    }

    .image-container {
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;
    }

    img {
      width: 100%;
      transform: scaleX(-1);
      position: absolute;
      top: -40px;
      left: 60px;
      z-index: 1;
    }

    h1 {
      color: #fff;
      position: relative;
      z-index: 1;

      ::v-deep span {
        color: #C3923F;
      }
    }

    ::v-deep p {
      color: #fff;
      position: relative;
      z-index: 1;
    }
  }

  .main-content { margin: 7rem 0rem 0 0; }
}

.btn {
  max-width: 320px;
  margin: 2rem auto 0px auto;
  display: block;
  text-align: center;
  border-color: #3A3C4D;
  color: #3A3C4D;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 0.875rem;
  width: 100%;

  &:before {
    margin-left: 194px;
    transform: translateY(-50%) scale(0.8);
    transform-origin: center;
  }
}

.main-content { margin-bottom: 0; }

.reperti {
  .container.timeline { max-width: unset; }

  padding: 1rem 0rem 6rem 0rem;
  background-color: $secondaryColor;
  position: relative;

  &::after {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/filigrana-la-storia.png'), url('/images/filigrana-la-storia.png');
    background-repeat: repeat-y, repeat-y;
    background-position-x: -75%, 150%;
    background-position-y: 0%, 115%;
    background-size: 75%, 60%;
    opacity: .6;
    z-index: 0;
    pointer-events: none;
  }

  .section-title {
    color: #fff;
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 4rem;

    ::v-deep span {
      color: $primaryColor;
      word-wrap: break-word;
    }
  }

  .acapo { display: none; }
  .list { padding-left: 10px; }
}

@media (min-width: 350px) {
  .reperti {
    .section-title { font-size: 2rem; }
    .title { font-size: 1.5625rem; }
  }
}

@media (min-width: 576px) {
  .main-container.la-storia {
    header {
      img {
        height: 80%;
        width: auto;
        right: 0;
        left: auto;
      }
    }
  }
}

@media (min-width: 768px) {
  .reperti {
    padding-top: 2rem;
    padding-bottom: 17rem;
    .acapo { display: block; }
    .section-title { margin-bottom: 2rem; }
    .list { padding: 0; }
  }
}

@media (min-width: 992px) {
  .reperti {
    margin-top: 7rem;
    padding-top: 3rem;

    .section-title {
      font-size: 2.5rem;
      margin-bottom: 1em;
    }
  }
}

@media (min-width: 1024px) {
  .main-content {
    position: relative;
    margin-bottom: 0;

    &:before {
      content: "";
      top: -44rem;
      right: 0;
      position: absolute;
      background: url("../assets/cosa-fare-deco-1.png");
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: right top;
      z-index: -3;

    }

    .container { max-width: 960px; }
  }

  .main-container.la-storia {
    header {
      &:after {
        height: 130vh;
        top: -8rem;
      }

      >.container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      h1, p { width: 600px; }

      h1 {
        margin-bottom: 0;
        font-size: 3.75rem;
      }

      img {
        right: auto;
        transform: scaleX(1);
        left: 0;
        height: 120%;
        width: auto;
        top: -9rem;
      }
    }

    .intro-description {
      margin-top: 6rem;
      position: relative;
      max-width: 900px;
    }
  }
}

@media (min-width: 1400px) {
  .reperti {
    &:before {
      background: url("../assets/taglio-la-storia.png");
      top: -690px;
      content: "";
      left: auto;
      right: 0;
      width: 306px;
      height: 690px;
      z-index: 4;
      position: absolute;
      opacity: 1;
      background-size: cover;
    }
  }
}

</style>