<template>
    <div class="wrapper">
        <div class="item">
            <!--<div class="title-container" @click="selectReperto(itemdata.attributes.drupal_internal__nid)">-->
                <router-link class="title-container" :to="this.ctTitle" @click.native="selectReperto(itemdata.attributes.drupal_internal__nid)">
                <h3 class="time">{{ itemdata.attributes.field_reperto_periodo }}</h3>
                <p class="title">{{ itemdata.attributes.title }}</p>
                <img src="../assets/arrow-icon-circle.svg" alt="" class="to-detail">
            <!--</div>-->
            </router-link>

            <div class="image">
                <images :imagedata="itemdata" :fieldname="'field_reperto_foto'"
                    :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
            </div>
        </div>
    </div>
</template>

<script>
    import Images from '@/components/Images'
    export default {
        name: "item-tile",
        data: () => {
            return {}
        },
        components: {
            Images
        },
        props: {
            routesdata: Array,
            itemdata: Object
        },
        computed: {
            ctTitle() {
                var t = (this.lang == this.defaultLang ? '' : ('/' + this.lang)) + "/not-found"
                this.routesdata.forEach(f => {
                    if (f.lang == this.lang && parseInt(f.nid) == this.itemdata.attributes
                        .drupal_internal__nid) {
                        t = f.path
                    }
                })
                return t
            },
            lang() {
                return this.$store.state.currentLang
            }
        },
        methods: {
            selectReperto(id) {
                this.$store.commit('SET_SELECTED_REPERTO', id)
                console.log('go to', this.ctTitle)
                //this.$router.push(this.ctTitle)
            },
            goToCT() {
                this.$router.push(
                    //(this.lang==this.defaultLang ? '' : ('/' + this.lang )) + 
                    //'/item/' + this.expertdata.attributes.drupal_internal__nid + '/' + 
                    this.ctTitle
                )
            }
        },
        mounted() {
            /*this.routesdata.forEach(r => {
                if(parseInt(r.nid)==this.itemdata.attributes.drupal_internal__nid && r.lang==this.lang) {
                    this.ctTitle = r.path
                }
            })*/
        },
        watch: {
            /*itemdata() {
                this.routesdata.forEach(r => {
                    if(parseInt(r.nid)==this.itemdata.attributes.drupal_internal__nid && r.lang==this.lang) {
                        this.ctTitle = r.path
                    }   
                })
            }*/
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

    .title-container {
        z-index: 3;
        position: relative;
        text-decoration: none;
    }

    .wrapper .item {
        padding: 0px 10px 70px 10px;
        position: relative;
        display: flex;
        flex-direction: column-reverse;

        .to-detail {
            right: 0;
            background-color: transparent;
            border-radius: 50%;
            transition: transform, background-color 300ms ease;
        }

        &::before {
            content: '';
            height: 100%;
            width: 1px;
            background-color: #898A8F;
            display: block;
            position: absolute;
            bottom: 0;
            left: -5px;
            z-index: 1;
        }

        .image {
            display: flex;
            justify-content: center;
            position: relative;
            max-width: 500px;
            margin: 2rem auto 0 auto;

            &:before {
                content: "";
                width: 80vw;
                height: 80vw;
                max-height: 400px;
                max-width: 400px;
                //height: 100%;
                background-color: #292932d0;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                opacity: .6;
                transform: translate3d(-50%, -50%, 0);
                z-index: 0;
                transition: all 300ms ease;
            }

            ::v-deep picture>img,
            img {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;
                object-fit: contain;
            }

            ::v-deep picture {
                width: 100%;
            }
        }

        .title {
            position: relative;
            z-index: 4;
            transition: all 300ms ease;
            color: #fff;
            font-size: 1.5em;
            line-height: 2rem;
            margin-top: 10px;
        }

        .time {
            color: #5F8669;
            margin-top: 0;
            margin-bottom: 0px;
            font-size: 1.2rem;
            text-transform: none;
            position: relative;
            z-index: 4;

            &:after {
                content: "";
                height: 10px;
                width: 10px;
                border-radius: 5px;
                background-color: rgb(137 138 143);
                display: block;
                position: absolute;
                top: 6px;
                left: -18px;
                box-shadow: 0px 0px 0px 9px #181923;
            }
        }
    }

    //.wrapper:hover {
    .title-container:hover {
        cursor: pointer;
        display: block;
        //.item {
            .to-detail {
                transform: scale(1.2);
                transition: 0.2s;
                background-color: rgba(#fff, .3);
            }

            .title {
                color: $primaryColor;
                transition: all 300ms ease;
            }
        //}
    }

    @media (min-width: 768px) {
        .wrapper {
            margin-bottom: -9rem;
        }

        .wrapper .item .time:after {
            left: -25px;
        }

        .wrapper:nth-child(even) {
            .time {
                &:after {
                    left: auto;
                    right: -24px;
                    z-index: 9;
                    top: 10px;
                }
            }
        }

        .title-container {
            margin-top: 3rem;

            img {
                margin-top: 2rem;
            }

        }

        .wrapper:nth-child(even) {

            .to-detail {
                text-align: right;
                display: block;
                right: 0;
                left: auto;
                margin-right: 0;
                margin-left: auto;
            }
        }

        .wrapper .item .image {
            top: 0;
            margin: 0rem auto 0 auto;
            max-height: 346px;
        }


        .reperti {
            width: 100%;
            overflow: hidden;
        }

        .wrapper {
            position: relative;

            .item {
                padding-top: 0rem;
                max-width: 50%;
                padding: 0;
                position: relative;
                min-height: 588px;

                .title {
                    font-size: 1.8rem;
                    margin-bottom: 0;
                    line-height: 2.5rem;
                }

                .image {
                    width: 100%;
                    top: 50px;

                    &:before {
                        width: 47vw;
                        height: 47vw;
                    }
                }

                .time {
                    margin-top: 0;
                    font-size: 1.75rem;
                }

                &:after {
                    bottom: .6em;
                    top: unset;
                }

                &:before {
                    bottom: 1.6em;
                    top: unset;
                }
            }
        }

        .wrapper:nth-child(2n) .item {
            padding-right: 20px;
            margin-right: 50%;

            .title {
                text-align: right;
            }

            .time {
                text-align: right;
            }

            .to-detail {
                right: 20px;
                bottom: -80px;
            }

            &:before {
                right: 0;
                left: unset;
            }

            &:after {
                right: -15px;
                left: unset;
            }
        }

        .wrapper:nth-child(2n+1) .item {
            padding-left: 20px;
            margin-left: 50%;

            .to-detail {
                left: 20px;
                right: unset;
                bottom: -80px;
            }

            &:before {
                left: -1px;
            }

            &:after {
                left: 5px;
            }
        }

        .wrapper:first-child .item {
            &:before {
                height: calc(85% - 100px);
            }
        }


    }

    @media (min-width: 992px) {
        .item {
            min-height: 654px;
            justify-content: center;

            &:hover {
                .image:before {
                    width: 140%;
                }
            }

            .title {
                font-size: 1.576em;
            }

            .image {
                &:before {
                    width: 120%;
                }
            }
        }

        .item:first-child {
            &:before {
                height: 95%;
            }
        }
    }

    @media (min-width: 768px) {

        .wrapper:last-child,
        .wrapper:first-child {
            &:after {
                padding: 0;
                position: absolute;
                left: calc(50% - 0px);
                top: -160px;
                content: url("../../src/assets/dashed-timeline.png");
                transform: translateX(-50%);
                width: 2px;
            }
        }

        .wrapper:last-child {
            .item:before {
                height: calc(85% - 100px);
                top: 0;
                bottom: auto;
            }

            &:after {
                top: auto;
                bottom: 0;
                left: calc(50% - 3px);
                transform: rotate(180deg) translateX(-50%);
            }
        }

        .wrapper .item:before {
            bottom: 0;
            height: 100%;
        }

        .wrapper:first-child .item:before {
            height: calc(100% - 100px);
        }
    }

    @media (min-width: 1200px) {
        .wrapper .item {
            max-height: 600px;

            .image {
                max-width: 470px;
                max-height: 305px;
            }
        }

        .wrapper .item .title {
            font-size: 2.25rem;
            line-height: 2.75rem;
        }

        .wrapper:nth-child(even) .title-container {
            z-index: 3;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .title-container {
            > * {
                max-width: 600px;
            }
        }

        .wrapper .item .image:before {
            width: 60vw;
            height: 60vw;
            max-height: 580px;
            max-width: 580px;
        }

        .reperti .section-title {
            font-size: 3.125rem;
        }
    }

    @media (min-width: 1366px) {
        .wrapper {
            margin-bottom: -15rem;
        }

        .wrapper .item {
            max-height: 600px;

            .image {
                max-width: 470px;
                max-height: 405px;
            }
        }

        .wrapper:nth-child(7) {
            margin-top: 11rem;
        }

        .wrapper:nth-child(9) {
            margin-top: 11rem;
        }

        .wrapper:nth-child(11) {
            margin-top: 11rem;
        }

        .wrapper:nth-child(4) {
            margin-top: 9rem;
        }

    }
</style>